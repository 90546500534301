.mask-loader-wrapper{
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,.37);
  z-index: 1200;
  img{
    position: absolute;
    top: 40%;
    left: calc(50% - 30px);
    max-width: 60px;
  }
}

.app-loader-wrapper {
  img{
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    max-width: 60px;
  }
}